import React from 'react'
import Layout from '../components/layout'
import ServicesData from '../content/services_data.json'
import Service from '../components/Service'
import others from '../assets/images/others.jpg'

class Vehicles extends React.Component {

  render () {
    return (
      <Layout>
          <Service 
            name={ "Otros servicios" } 
            description={ "Queremos que no pierdas tu tiempo, por eso disponemos de todos los servicios en un mismo lugar, con el objetivo de facilitar la documentación requerida en cada trámite." } 
            img={ others }
            service_data={ ServicesData.other_services } />
      </Layout>
    )
  }
}

export default Vehicles